import React from "react"
import Layout from "../../components/layout/layout"
import FeedbackResult from "../../components/feedback/feedbackResult"

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function SurveyResult() {

  const { isClient, key } = useIsClient();

  if ( !isClient ) return null;


  return (
    <Layout showBottom="false" key={key}>
      <Seo title="Drops | Feedback Survey Dankeschön | Toothpaste Drops" path="/surveys/fbresult" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <FeedbackResult />
      </div>
    </Layout>
  )
}
