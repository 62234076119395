import React from "react"

import * as styles from "./feedbackResult.module.scss"

import ty from '../../images/ty_present.png'
import Container from 'react-bootstrap/Container'

// const SURVEY_SEND_API_URL = "https://drops-crm-backend.herokuapp.com/api/webhook/survey";
// const SURVEY_SEND_API_URL = "http://localhost:8080/api/webhook/survey"

export default function FeedbackResult() {

  const params = new URLSearchParams(window.location.search);

  const resultState = parseInt(params.get('fb'))

  if (params.get('fb') === null || (parseInt(params.get('fb')) !== 1 && parseInt(params.get('fb')) !== 2) || window.localStorage.getItem('qeid') === null) {
    window.location.href = "/"
    return;
  }

  return (
      <Container style={{ paddingTop: '100px'}} className={`mb-5 ${styles.tySurvey}`}>
        <div className="text-center">
          <img src={ty} alt="Vielen Dank" />
          <h2><span>Danke {window.localStorage.getItem('customer_name')}.</span></h2>
          {resultState === 1
          ? <>
            <h3>Für deine Unterstützung.</h3>
            <hr className="col-md-4 offset-md-4" />
            <p>Toll, dass dir unsere Drops so sehr gefallen! Als Dankeschön kannst du dir jetzt einmalig<br className="d-none d-md-block" />unser Monatspaket (ab 4 Monate) für <span className="font-weight-bold">40% Rabatt</span> über WhatsApp holen!<br /><br /><span className="font-weight-bold">Nachhaltigkeit darf nicht teurer sein!</span> Mit 40% Rabatt zahlst du nur 2,99€ pro Monat (entspricht ca. 1 Zahnpastatube).<br /></p>
            <ul className="list-unstyled mb-5">
              <li className="mb-2"><u>1 Person / 4 Monate:</u> Für nur <span className="font-weight-bold">11,95€</span> statt <s>15,90</s>€</li>
              <li><u>2 Person / 4 Monate:</u> Für nur <span className="font-weight-bold">23,88€</span> statt <s>31,80</s>€</li>
            </ul>
            <a className="btn btn-primary p-3 px-5 font-weight-bold" target="_blank" rel="noreferrer" href={`https://wa.me/4915735988383?text=Hallo Drops Team, ich m%C3%B6chte gerne das Monatspaket für 40% Rabatt bestellen!`}>Jetzt 40% über WhatsApp sichern</a>
          </>
          :
          <>
          <h3>Für dein ehrliches Feedback.</h3>
          <p>Schade, dass dir die aktuellen Drops nicht zugesagt haben. Das macht aber garnichts, denn wir sind dankbar für jegliches Feedback!<br /><br />Deshalb laden wir dich hiermit ein, an unserer offiziellen Testgruppe (Drops Circle) teilzunehmen. Dafür musst Du uns lediglich bei WhatsApp schreiben, dass Du teilnehmen möchtest, und Du erhältst von uns <b>kostenlos</b> ein weiteres Probierpaket, sobald die nächste Sorte erhältlich ist. Dein Probierpaket wird dann sowohl die neuen als auch die alten Drops enthalten, damit Du besser vergleichen kannst.<br /><br />Klicke dafür einfach auf folgenden Link:</p>
          <a className="btn btn-primary p-3" target="_blank" rel="noreferrer" href={`https://wa.me/4915735988383?text=Hallo Drops Team, ich m%C3%B6chte gerne in den Drops Circle. Meine E-mail lautet: ${window.localStorage.getItem('email')} - viele Grüße, ${window.localStorage.getItem('full_name')}`}>Jetzt dem Circle beitreten</a>
          </>
          }
        </div>
      </Container>
  )
}
